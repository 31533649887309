export const Events =
[
	{
		allDay: false,
		start: new Date('September 16, 2022 10:00'),
		end: new Date('October 16, 2022'),
		title: 'Coat Collection',
		location: '',
	},
	{
		allDay: true,
		start: new Date('October 29, 2022 10:00'),
		end: new Date('October 29, 2022'),
		title: 'Fall Coat Distribution',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 4, 2022 10:00'),
		end: new Date('November 4, 2022'),
		title: 'MLK High PTSA Community Partner',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 1, 2022 10:00'),
		end: new Date('December 9, 2022'),
		title: 'Christmas Toy Collection',
		location: '',
	},
	{
		allDay: false,
		start: new Date('December 17, 2022 10:00'),
		end: new Date('December 17, 2022'),
		title: 'Christmas Toy Distribution',
		location: '',
	},
	{
		allDay: false,
		start: new Date('October 5, 2022 10:00'),
		end: new Date('October 5, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('October 12, 2022 10:00'),
		end: new Date('October 12, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('October 19, 2022 10:00'),
		end: new Date('October 19, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('October 26, 2022 10:00'),
		end: new Date('October 26, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 2, 2022 10:00'),
		end: new Date('November 2, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 9, 2022 10:00'),
		end: new Date('November 9, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 16, 2022 10:00'),
		end: new Date('November 16, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 23, 2022 10:00'),
		end: new Date('November 23, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('November 30, 2022 10:00'),
		end: new Date('November 30, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('December 7, 2022 10:00'),
		end: new Date('December 7, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('December 14, 2022 10:00'),
		end: new Date('December 14, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('December 21, 2022 10:00'),
		end: new Date('December 21, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('December 28, 2022 10:00'),
		end: new Date('December 28, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: false,
		start: new Date('February 16, 2022 10:00'),
		end: new Date('February 16, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: true,
		start: new Date('February 23, 2022 10:00'),
		end: new Date('February 23, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: true,
		start: new Date('March 2, 2022 10:00'),
		end: new Date('March 2, 2022'),
		title: 'Fulton County Jail Facilitation',
		location: '',
	},
	{
		allDay: true,
		start: new Date('March 4, 2022 8:00'),
		end: new Date('March 4, 2022'),
		title: 'PTSA MLK High Community Sponsor',
		location: '',
	},
	{
		allDay: true,
		start: new Date('March 19, 2022 11:00'),
		end: new Date('March 19, 2022'),
		title: 'POH Community Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('April 8, 2022 12:00'),
		end: new Date('April 8, 2022'),
		title: 'Extended Stay Job Workshop',
		location: '',
	},
	{
		allDay: false,
		start: new Date('July 2, 2021 12:00'),
		end: new Date('July 2, 2021'),
		title: 'Extended Stay Job Workshop',
		location: '',
	},
	{
		allDay: true,
		start: new Date('July 16, 2021 12:00'),
		end: new Date('July 16, 2021'),
		title: 'Extended Stay Job Workshop',
		location: '',
	},
	{
		allDay: true,
		start: new Date('July 24, 2021 12:00'),
		end: new Date('July 24, 2021'),
		title: 'School Supply Distribution ',
		location: '',
	},
	{
		allDay: true,
		start: new Date('July 30, 2021 12:00'),
		end: new Date('July 30, 2021'),
		title: 'Extended Stay Job Workshop ',
		location: '',
	},
	{
		allDay: true,
		start: new Date('December 18, 2021 12:00'),
		end: new Date('December 18, 2021'),
		title: 'Christmas Holiday Gift Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('June 22, 2021 12:00'),
		end: new Date('June 22, 2021'),
		title: 'Food Distribution Ray of Hope Christian Church',
		location: '',
	},
	{
		allDay: true,
		start: new Date('June 17, 2023 12:00'),
		end: new Date('June 17, 2023'),
		title: 'Father\'s Day Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('July 2, 2023 12:00'),
		end: new Date('July 2, 2023'),
		title: 'Tent Encampment Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('August 5, 2023 12:00'),
		end: new Date('August 5, 2023'),
		title: 'Back to School Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('November 11, 2023 12:00'),
		end: new Date('November 11, 2023'),
		title: 'Homeless Veterans Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('December 8, 2023 12:00'),
		end: new Date('December 10, 2023 12:00'),
		title: 'Christmas and Holiday Gift Giveaway',
		location: '',
	},
	{
		allDay: true,
		start: new Date('November 6, 2023 12:00'),
		end: new Date('December 3, 2023 12:00'),
		title: 'Holiday Gift Collection',
		location: '',
	},
	{
		allDay: true,
		start: new Date('February 14, 2024 12:00'),
		end: new Date('February 14, 2024'),
		title: 'Valentine\'s Day Veteran Cupid boxes',
		location: '',
	},
	{
		allDay: true,
		start: new Date('March 24, 2024 12:00'),
		end: new Date('March 24, 2024'),
		title: 'Tent City Easter Basket Giveaway',
		location: '',
	},
	{
		allDay: true,
		start: new Date('May 27, 2024 12:00'),
		end: new Date('May 27, 2024'),
		title: 'Memorial Day Meal Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('August 3, 2024 12:00'),
		end: new Date('August 3, 2024'),
		title: 'Backpack Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('September 26, 2024 12:00'),
		end: new Date('September 28, 2024 12:00'),
		title: 'Fall Coat Collection Drive',
		location: '',
	},
	{
		allDay: true,
		start: new Date('October 31, 2024 12:00'),
		end: new Date('October 31, 2024'),
		title: 'Halloween Food Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('November 1, 2024 12:00'),
		end: new Date('December 14, 2024 12:00'),
		title: 'Gift Collection',
		location: '',
	},
	{
		allDay: true,
		start: new Date('November 23, 2024 10:00 AM'),
		end: new Date('November 23, 2024'),
		title: 'Turkey Giveaway',
		location: '',
	},
	{
		allDay: true,
		start: new Date('December 21, 2024 11:00 AM'),
		end: new Date('December 21, 2024 1:00 PM'),
		title: 'Gift Distribution',
		location: '',
	},
	{
		allDay: true,
		start: new Date('October 10, 2024 12:00'),
		end: new Date('October 10, 2024'),
		title: 'Hurt Park - Backpack Distribution',
		location: 'Hurt Park',
	},
];